const conductStyleClasses = {
  
  p:`
  leading-8 
  mt-8 
  text-sm
  `,
  li:`
  leading-8 
  mt-2 
  text-sm
  `,
  nestedLi:`
  leading-8 
  ml-3 
  text-sm
  `,
}
export default conductStyleClasses;
