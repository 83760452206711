const privacyStyleClasses = {
  outlineHeader:`
    text-l
    font-bold
    mr-10
    leading-loose
  `,

  bodyHeader:`
    text-xl
    font-bold
  `
  ,
  body:`
    mb-10
    leading-7
  `,



};

export default privacyStyleClasses;
